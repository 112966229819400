import React, { useEffect } from "react";
import { useBookingContext } from "../context/bookingContext";
import StepperHorizontal from "../components/stepperHorizontal";
import BasketSummary from "../components/basketSummary";
import BookingForm from "../components/bookingForm";
import PaymentForm from "../components/paymentForm";
import DateAndTimeBasketOffCanvas from "../components/dateAndTimeBasketOffCanvas";

const BookingProcess = () => {
  const { activeStep, setActiveStep, paymentEnabled, isBookingProcessOpen } =
    useBookingContext();

  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth <= 1023;
      if (!isSmallScreen && activeStep === 2) {
        setActiveStep(1);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeStep, setActiveStep]);

  if (!isBookingProcessOpen) return null;

  const renderStepContent = () => {
    return activeStep === 2 ? (
      <BasketSummary paymentEnabled={paymentEnabled} />
    ) : (
      <BookingForm />
    );
  };

  return (
    <>
      <div className="fixed inset-0 z-50 items-center justify-center">
        <div className="bg-gray-100 w-full h-full overflow-y-auto shadow-lg transform transition-transform 2xl:1/2 duration-300 ease-in-out flex flex-col">
          <div className="bg-white shadow-sm sticky top-0 left-0 z-10">
            <StepperHorizontal />
          </div>
          <div className="time-and-personal-data px-4 mx-auto mt-10 mb-5 w-full md:w-full lg:w-5/6 xl:w-3/4 2xl:w-3/5 flex flex-row">
            <div className="bg-white overflow-y-auto flex-grow mb-24 md:mb-0">
              <div className={`${activeStep === 2 ? "md:hidden" : ""} md:p-4`}>
                {renderStepContent()}
              </div>
            </div>
            <div className="bg-white ml-4 w-full md:w-1/3 lg:w-1/3 xl:w-1/4 flex-none h-fit hidden md:block sticky top-28">
              <BasketSummary paymentEnabled={paymentEnabled} />
            </div>
          </div>
        </div>
        <div className="md:hidden">
          <DateAndTimeBasketOffCanvas />
        </div>
        {activeStep === 2 && paymentEnabled && <PaymentForm />}
      </div>
    </>
  );
};

export default BookingProcess;
