import React from "react";
import { useBookingContext } from "../context/bookingContext";

export default function CustomCarousel() {
  const { openBusiness } = useBookingContext();

  return (
    <div
      className="relative bg-cover bg-center h-screen md:rounded-t-md flex max-h-80 items-center"
      style={{ backgroundImage: `url(${require("../assets/" + openBusiness.hero)})` }}
    >
      {/* Capa de fondo negro con opacidad */}
      <div
        className="absolute inset-0 bg-black md:rounded-t-md opacity-50"
      ></div>
      {/* Contenido del héroe */}
      <div className="container mx-auto text-center text-white z-10">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">{openBusiness.name}</h1>
        {/* Subtítulo */}
        <h2 className="text-lg italic md:text-xl mb-8">{openBusiness.slogan}</h2>
        {/* Botón tipo CTA */}
        <a
            href="#reservar"
            class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-white bg-accent-normal rounded-lg hover:bg-accent-hover focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            RESERVAR
          </a>
      </div>
    </div>
  );
};
