import React, { useEffect, useState } from "react";
import { ClockIcon } from "@heroicons/react/24/outline";
import { useBookingContext } from "../context/bookingContext";

const CountdownTimer = () => {
  const { time } = useBookingContext();
  const [remainingTime, setRemainingTime] = useState(time);

  useEffect(() => {
    setRemainingTime(time);
  }, [time]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div className="text-center">
      <div className="flex items-center">
        <div className="w-6 h-6 mr-2">
          <ClockIcon className="w-full h-full text-gray-500" />
        </div>
        <div className="text-normal w-20 font-semibold text-gray-500">
          {formatTime(remainingTime)}
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
