// BookingContext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { availableHours } from "../utils/bookingHelper";
import Reservation from "../utils/reservation";
import MyDate from "../utils/date";
import ServerAPI from "../api/api";
import {
  ERROR_NOK,
  PAYMENT_TIMEOUT,
  PAYMENT_OK,
  PAYMENT_NOK,
} from "../utils/constants";

const BookingContext = createContext();

export const BookingProvider = ({
  children,
  pageToShow = null,
  initialBusiness = null,
}) => {
  const [paymentFormData, setPaymentFormData] = useState({
    Ds_MerchantParameters: "",
    Ds_Signature: "",
  });

  const myDate = new MyDate();
  const api = useMemo(() => new ServerAPI("Server"), []);
  const [bookingPage, setBookingPage] = useState(pageToShow);
  console.log(pageToShow);
  const [openBusiness, setOpenBusiness] = useState(initialBusiness);

  const [time, setTime] = useState(PAYMENT_TIMEOUT);
  const [timerId, setTimerId] = useState(null);

  const [currentService, setCurrentService] = useState([]);
  const [professionalAvailableHours, setProfessionalAvailableHours] = useState(
    []
  );
  const [serverData, setServerData] = useState(null);
  const [serviceProfessionals, setServiceProfessionals] = useState([]);
  const [selectedProfessionalId, setSelectedProfessionalId] = useState("");
  const [availableAppointmentsRaw, setAvailableAppointmentsRaw] = useState([]);
  const [busyHours, setBusyHours] = useState([]);
  const [isBookingProcessOpen, setIsBookingProcessOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [formValidation, setFormValidation] = useState(false);
  const [isContinueButtonActive, setIsContinueButtonActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState("");
  const [introducedEmail, setIntroducedEmail] = useState("");
  const [introducedName, setIntroducedName] = useState("");
  const [introducedComments, setIntroducedComments] = useState("");

  const steps = [
    { label: "Fecha y hora", completed: activeStep >= 0 },
    { label: "Datos Personales", completed: activeStep >= 1 },
    { label: "Resumen", completed: activeStep >= 2 },
  ];

  const updatePaymentFormData = (newData) => {
    setPaymentFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const updatePaymentEnabled = (status) => {
    setPaymentEnabled(status);
  };

  const handleContinue = () => {
    blockTimeSlot();
    setupBookingTimeout();
    setActiveStep(1);
  };

  const handleReview = () => {
    setActiveStep(2);
  };

  const handleConfirm = async () => {
    cancelBookingTimeout();

    try {
      const response = await api.confirmReservation(
        serverData.reservationId,
        openBusiness.id,
        selectedProfessionalId,
        selectedProfessional,
        introducedName,
        introducedEmail,
        selectedService,
        currentService.price
      );
      if (response.status === 200) {
        setBookingPage(PAYMENT_OK);
      } else {
        setBookingPage(PAYMENT_NOK);
      }
    } catch (error) {
      console.error("Error al llamar a confirmReservation", error);
    }
  };

  const handleGoToPay = async () => {
    cancelBookingTimeout();

    try {
      const response = await api.preConfirmReservation(
        serverData.reservationId,
        openBusiness.id,
        selectedProfessionalId,
        selectedProfessional,
        introducedName,
        introducedEmail,
        selectedService,
        currentService.price
      );
      if (response.status !== 200) {
        setBookingPage(PAYMENT_NOK);
      }
    } catch (error) {
      console.error("Error al llamar a preConfirmReservation:", error); // Agrega este log
    }
  };

  useEffect(() => {
    if (!openBusiness) {
      return;
    }

    setPaymentEnabled(openBusiness.paymentEnabled);
    const nearestReservationHours = openBusiness.nearestReservationHours;
    const farthestReservationDays = openBusiness.farthestReservationDays;

    api
      .initServer(
        nearestReservationHours,
        farthestReservationDays,
        openBusiness.id
      )
      .then(async (res) => {
        setBusyHours(res.busyHours);
      });
  }, [openBusiness, api, setBookingPage]);

  const handleSelectedDateChange = (date) => {
    console.log(date);
    setSelectedDate(date);
    setSelectedHour("");
    setProfessionalAvailableHours([]);
    console.log("ID: ", selectedProfessionalId);
    availableHoursByService(date, selectedProfessionalId);
  };

  const availableHoursByDay = (day, availableAppointmentsRaw) => {
    const selectedDateData = myDate.dateShortFormat(day).split("-");
    const selectedDate =
      selectedDateData[0] +
      "/" +
      selectedDateData[1] +
      "/" +
      selectedDateData[2];

    const availableAppointmentsHours = availableAppointmentsRaw
      .filter((element) => element.substring(0, 10) === selectedDate)
      .map((element) => element.substring(12, 17));

    setProfessionalAvailableHours(availableAppointmentsHours);
  };

  const handleSelectedServiceChange = (selectedService) => {
    console.log(selectedService);
    setSelectedService(selectedService[0]);

    const currentService = openBusiness.services.find(
      (service) => service.name === selectedService[0]
    );

    const professionalNames = currentService.professionals.map(
      (professionalId) => {
        const professional = openBusiness.professionals.find(
          (prof) => prof.id === professionalId
        );
        return professional ? professional.name : "Profesional no encontrado";
      }
    );

    setCurrentService(currentService);
    setServiceProfessionals(professionalNames);
    setProfessionalAvailableHours([]);
    setSelectedProfessional("");
    setSelectedDate("");
    setSelectedHour("");
  };

  const handleSelectedProfessionalChange = (event) => {
    const selectedProfessionalId = openBusiness.professionals.findIndex(
      (professional) => professional.name === event.target.value
    );

    setSelectedProfessional(event.target.value);
    setSelectedProfessionalId(selectedProfessionalId);

    availableHoursByService(selectedDate, selectedProfessionalId);
    setProfessionalAvailableHours([]);
    setSelectedDate("");
    setSelectedHour("");
  };

  const availableHoursByService = async (
    selectedDate,
    selectedProfessionalId
  ) => {
    const reservationFreqMinutes = openBusiness.reservationFreqMinutes;
    const serviceDuration = currentService.duration;
    const nearestReservationHours = openBusiness.nearestReservationHours;
    const farthestReservationDays = openBusiness.farthestReservationDays;

    const availableAppointmentsRaw = availableHours(
      busyHours[selectedProfessionalId],
      nearestReservationHours,
      farthestReservationDays,
      reservationFreqMinutes,
      serviceDuration,
      selectedDate
    );

    if (availableAppointmentsRaw !== ERROR_NOK["NON_APPOINTMNETS"]) {
      setAvailableAppointmentsRaw(availableAppointmentsRaw);
      availableHoursByDay(selectedDate, availableAppointmentsRaw);
    } else {
      console.log("systemError:" + ERROR_NOK["NON_APPOINTMENTS"]);
    }
  };

  const blockTimeSlot = async () => {
    const reservation = new Reservation(
      selectedService,
      "Omar",
      "omar15able@gmail.com",
      myDate.dateShortFormat(selectedDate),
      selectedHour,
      false
    );
    const serviceDuration = currentService.duration;
    const servicePrice = currentService.price;

    const response = await api.blockTimeSlot(
      reservation,
      serviceDuration,
      servicePrice,
      openBusiness.id,
      selectedProfessionalId
    );
    const data = await response.json();

    if (data.reservationId === "busy") {
      console.log("systemError: " + ERROR_NOK["BUSY_EVENT"]);
    } else {
      setServerData(data);
      setPaymentFormData({
        Ds_MerchantParameters: data.merchantParameters,
        Ds_Signature: data.signature,
      });
      console.log("Reservando: " + data.reservationId);
    }
  };

  const setupBookingTimeout = () => {
    setTime(PAYMENT_TIMEOUT);
    if (!timerId) {
      const id = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000); // Actualiza el tiempo cada segundo
      setTimerId(id);
    }
  };

  const cancelBookingTimeout = () => {
    if (timerId !== null) {
      clearInterval(timerId);
      setTimerId(null);
    }
  };

  const handleBookingTimeout = async () => {
    console.log("¡Se agotó el tiempo!");
    deleteReservation();
    cleanBookingData();
    cancelBookingTimeout();
  };

  useEffect(() => {
    if (time === 0) {
      handleBookingTimeout();
    }
  }, [time]);

  const deleteReservation = async () => {
    try {
      const response = await api.deleteReservation(
        serverData.reservationId,
        openBusiness.id,
        selectedProfessionalId
      );

      if (response.status === 200) {
        console.log("Eliminando: " + serverData.reservationId);
      } else {
        console.log(
          "No se ha podido eliminar la reserva: " + serverData.reservationId
        );
      }
    } catch (error) {
      console.error("Error al llamar a deleteReservation:", error);
    }
  };

  const handleBookingClose = () => {
    setTimeout(() => {
      setIsBookingProcessOpen(false);
    }, 300);

    cleanBookingData();
    cancelBookingTimeout();
  };

  const cleanBookingData = () => {
    setPaymentFormData({
      Ds_MerchantParameters: "",
      Ds_Signature: "",
    });
    setSelectedService("");
    setSelectedProfessional("");
    setSelectedProfessionalId("");
    setSelectedDate("");
    setProfessionalAvailableHours([]);
    setSelectedHour("");
    setActiveStep(0);
  };

  const contextValue = {
    handleSelectedDateChange,
    handleSelectedServiceChange,
    handleSelectedProfessionalChange,
    openBusiness,
    setOpenBusiness,
    isBookingProcessOpen,
    setIsBookingProcessOpen,
    paymentFormData,
    updatePaymentFormData,
    currentService,
    serviceProfessionals,
    setServiceProfessionals,
    activeStep,
    setActiveStep,
    paymentEnabled,
    updatePaymentEnabled,
    selectedService,
    setSelectedService,
    selectedProfessional,
    setSelectedProfessional,
    selectedDate,
    setSelectedDate,
    selectedHour,
    setSelectedHour,
    professionalAvailableHours,
    introducedEmail,
    setIntroducedEmail,
    introducedName,
    setIntroducedName,
    introducedComments,
    setIntroducedComments,
    formValidation,
    setFormValidation,
    isContinueButtonActive,
    setIsContinueButtonActive,
    availableAppointmentsRaw,
    steps,
    time,
    handleContinue,
    handleReview,
    handleConfirm,
    handleGoToPay,
    handleBookingClose,
    cancelBookingTimeout,
    deleteReservation,
    cleanBookingData,
    bookingPage,
  };

  return (
    <BookingContext.Provider value={contextValue}>
      {children}
    </BookingContext.Provider>
  );
};

export const useBookingContext = () => {
  const context = useContext(BookingContext);
  if (!context) {
    throw new Error(
      "useBookingContext debe ser utilizado dentro de BookingProvider"
    );
  }
  return context;
};
