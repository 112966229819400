import React from "react";
import { useBookingContext } from "../context/bookingContext";
import { REDSYS_URL, REDSYS_PAY } from "../utils/constants";

export default function PaymentForm() {
  const { paymentFormData, formValidation, handleGoToPay } = useBookingContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    await handleGoToPay();
    
    if (formValidation) {
      document.getElementById('bookingForm').submit();
    }
  };

  return (
    <form className="w-full" id="bookingForm" onSubmit={handleSubmit} action={REDSYS_URL} method="POST">
      <input
        type="hidden"
        name="Ds_SignatureVersion"
        value={REDSYS_PAY}
      />
      <input
        type="hidden"
        name="Ds_MerchantParameters"
        value={paymentFormData.Ds_MerchantParameters}
      />
      <input
        type="hidden"
        name="Ds_Signature"
        value={paymentFormData.Ds_Signature}
      />
      <button
        type="submit"
        className={`text-white w-full bottom-0 bg-accent-normal hover:bg-accent-hover focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 ${!formValidation ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={!formValidation}
      >
         {"Pagar"}
      </button>
    </form>
  );
}
