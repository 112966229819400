import React from "react";
import Button from "@mui/material/Button";
import "../style/paymentOk.css";

import notFound from "../assets/global/not_found_error.svg";

function NotFound(props) {
  return (
    <main>
      <div className="container vertical-center text-center align-items-center centerMyDiv">
        <h6>
          <strong>PÁGINA NO ENCONTRADA</strong>
        </h6>
        <p>
          Lo sentimos, esta página no se encuentra disponible en RESERVATUCITA.COM
        </p>
        <img src={notFound} width="200" height="200" alt="Error 404" />
        <Button
          style={{
            borderRadius: 0,
            marginTop: 35,
            color: '#dc3545',
            borderColor: '#dc3545'
          }}
          variant="outlined"
          size="medium"
          color="success"
          href={"https://www.reservatucita.com"}
        >
          Volver
        </Button>
      </div>
    </main>
  );
}

export default NotFound;
