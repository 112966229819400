import React from "react";
import Card from "../components/card";
import Contact from "../components/contact";
import CallToAcction from "../components/callToAcction";

/*
Anotaciones (no borrar):
El tamaño de logo debe ser multiple de 1899 de ancho y 950 de alto en pixeles
*/
function Home(props) {
  return (
    <main className="container bg-white mx-auto my-20 px-8 shadow-md">
      <section class="my-8 bg-white dark:bg-gray-900">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Encuentra tu lugar favorito y reserva una cita
        </h2>
        <CallToAcction />
      </section>
      <section className="bg-white dark:bg-gray-900 my-8">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Explora nuestros negocios
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {props.businesses.map((business, id) => (
            <Card business={business} id={id} />
          ))}
        </div>
      </section>
      <section id="contact" className="my-8">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Contactanos
        </h2>
        <Contact />
      </section>
    </main>
  );
}

export default Home;
