import React, { useEffect } from "react";
import { useBookingContext } from "../context/bookingContext";
import illustration from "../assets/global/payment_fail.svg";

const PaymentStatusOk = () => {
  const { openBusiness } = useBookingContext();

  useEffect(() => {
    console.log("PaymentStatusOk");
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="flex flex-col bg-white rounded-md mx-auto my-16 md:my-20 shadow-md h-screen md:h-auto">
      <div
        className="relative bg-cover bg-center h-screen md:rounded-t-md flex max-h-52 items-center"
        style={{
          backgroundImage: `url(${require("../assets/" + openBusiness.hero)})`,
        }}
      >
        <div className="absolute inset-0 bg-black md:rounded-t-md opacity-50"></div>
        <div className="container mx-auto text-center text-white z-10">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            {openBusiness.name}
          </h1>
        </div>
      </div>
      <div className="container items-center justify-center px-4 py-4 pb-12 mx-auto w-full md:w-1/2 text-center">
        <img
          src={illustration}
          width="200"
          height="200"
          alt="success"
          className="mx-auto my-4"
        />
        <p className="">
          <strong>¡Upss! Algo ha ido mal.</strong>
        </p>
        <p className="py-8 leading-6">
          Lo sentimos, ha habido un problema al procesar tu reserva en{" "}
          {openBusiness.name}. Por favor, verifica la información y vuelve a
          intentarlo. Estamos aquí para ayudarte si tienes alguna pregunta.
          ¡Gracias por tu comprensión!
        </p>
        <a href={`https://www.reservatucita.com${openBusiness.relURL}`}>
          <button
            type="button"
            className="text-black hover:text-white border border-fail hover:bg-fail focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
          >
            Volver al inicio
          </button>
        </a>
      </div>
    </main>
  );
};

export default PaymentStatusOk;
