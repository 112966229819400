import React from "react";

export default function CallToAcction() {
  return (
    <div class="py-8 mx-auto">
      <div class="max-w-screen-xl">
        <p class="mb-8 font-light text-gray-500 sm:text-xl dark:text-gray-400">
          ¡Bienvenido! Aquí puedes descubrir y programar citas en tus lugares
          preferidos. ¿Necesitas un corte de pelo o tal vez un relajante día de
          spa? En nuestro sitio, encontrarás todo lo que necesitas para
          disfrutar de una experiencia única y personalizada.
        </p>
        <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          <a
            href="#contact"
            class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-white bg-accent-normal rounded-lg hover:bg-accent-hover focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            Contactanos
          </a>
          <a
            href="https://www.instagram.com/reservatucita.com_/"
            class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <svg
              class="mr-2 -ml-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
            </svg>
            Instagram
          </a>
        </div>
      </div>
    </div>
  );
}
