import React from "react";
import CustomCarousel from "../components/carsouel";
import BarberShopDetails from "../components/barberShopDetails";
import Gallery from "../components/gallery";
import MapMarker from "../components/mapMarker";
import BarberServices from "../components/barberServices";
import ServicesBasketOffCanvas from "../components/servicesBasketOffCanvas";
import BookingProcess from "./bookingProcess";
import { useBookingContext } from "../context/bookingContext";
import PaymentStatusOk from "../pages/paymentOk";
import PaymentStatusNok from "../pages/paymentNok";
import CancelReserve from "./cancelReserve";
import {
  BOOKING_FORM,
  PAYMENT_OK,
  PAYMENT_NOK,
  RES_CANCEL,
} from "../utils/constants";

export default function BarberShopPage() {
  const { bookingPage } = useBookingContext();

  switch (bookingPage) {
    case PAYMENT_OK:
      return <PaymentStatusOk />;
    case PAYMENT_NOK:
      return <PaymentStatusNok />;
    case RES_CANCEL:
      return <CancelReserve />;
    case BOOKING_FORM:
      return (
        <main className="container bg-white rounded-md mx-auto my-16 md:my-20 shadow-md md:w-5/6">
          <CustomCarousel />
          <div className="container px-4 py-4 mx-auto w-full xl:w-2/3 2xl:w-1/2">
            <div className="grid grid-cols-12 gap-4 pt-4">
              <div className="col-span-12 md:col-span-12 h-auto">
                <BarberShopDetails />
                <BarberServices />
              </div>
              <div className="col-span-12 md:col-span-12">
                <Gallery />
              </div>
              <div className="col-span-12 md:col-span-12">
                <MapMarker />
              </div>
              <div className="col-span-12 md:col-span-12">
                <ServicesBasketOffCanvas />
              </div>
            </div>
          </div>
          <BookingProcess />
        </main>
      );
    default:
      return "¡Ups, no deberías haber llegado aquí!";
  }
}
