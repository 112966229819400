import React from "react";

function CookiesPolicy() {
  return (
    <main>
      <div className="container p-4 md:p-20 pt-28 space-y-6">
        <h1 className="text-xl">Política de Cookies</h1>
        <h3>ANTECEDENTES</h3>
        <p className="paragraphs-policy">
          De conformidad con la normativa española que regula el uso de cookies
          en relación a la prestación de servicios de comunicaciones
          electrónicas, recogida en el artículo 22.2 de la Ley 34/2002, del 11
          de julio, de servicios de la sociedad de la información y de comercio
          electrónico, le informamos sobre las cookies utilizadas en el sitio
          web de MOHAMED EL QAMAR y el motivo de su uso.
        </p>
        <h3>¿QUE SON LAS COOKIES?</h3>
        <p className="paragraphs-policy">
          Una cookie es un fichero que se descarga en su ordenador al acceder a
          determinadas páginas web. Las cookies permiten a una página web, entre
          otras cosas, almacenar y recuperar información sobre los hábitos de
          navegación de un usuario o de su equipo y, dependiendo de la
          información que contengan y de la forma en que utilice su equipo,
          pueden utilizarse para reconocer al usuario. Las cookies se asocian
          únicamente a un usuario anónimo y su ordenador o dispositivo y no
          proporcionan referencias que permitan conocer sus datos personales.
        </p>
        <h3>TIPOS DE COOKIES</h3>
        <p className="paragraphs-policy">
          3.1.- Según la entidad que las gestiona a.- Cookies propias: Son
          aquéllas que se envían al equipo terminal del usuario desde un equipo
          o dominio gestionado por el propio editor y desde el que se presta el
          servicio solicitado por el usuario. b.- Cookies de terceros: Son
          aquéllas que se envían al equipo terminal del usuario desde un equipo
          o dominio que no es gestionado por el editor, sino por otra entidad
          que trata los datos obtenidos a través de las cookies. 3.2.- Según la
          finalidad Existen muchas finalidades para el uso de las cookies. Según
          la finalidad para la que se traten los datos obtenidos a través de las
          cookies, algunas de las finalidades pueden ser: a) Cookies técnicas:
          son aquellas que permiten al usuario la navegación a través de una
          página web, plataforma o aplicación y la utilización de las diferentes
          opciones o servicios que en ella existan, incluyendo aquellas que el
          editor utiliza para permitir la gestión y operativa de la página web y
          habilitar sus funciones y servicios, como, por ejemplo, controlar el
          tráfico y la comunicación de datos, identificar la sesión, acceder a
          partes de acceso restringido, recordar los elementos que integran un
          pedido, realizar el proceso de compra de un pedido, gestionar el pago,
          controlar el fraude vinculado a la seguridad del servicio, realizar la
          solicitud de inscripción o participación en un evento, contar visitas
          a efectos de la facturación de licencias del software con el que
          funciona el servicio (sitio web, plataforma o aplicación), utilizar
          elementos de seguridad durante la navegación, almacenar contenidos
          para la difusión de vídeos o sonido, habilitar contenidos dinámicos
          (por ejemplo, animación de carga de un texto o imagen) o compartir
          contenidos a través de redes sociales. Las cookies técnicas estarán
          exceptuadas del cumplimiento de las obligaciones establecidas en el
          artículo 22.2 de la LSSI cuando permitan prestar el servicio
          solicitado por el usuario, como ocurre en el caso de las cookies
          enumeradas en los párrafos anteriores. Sin embargo, si estas cookies
          se utilizan también para finalidades no exentas (por ejemplo, para
          fines publicitarios comportamentales), quedarán sujetas a dichas
          obligaciones. b) Cookies de preferencias o personalización: son
          aquellas que permiten recordar información para que el usuario acceda
          al servicio con determinadas características que pueden diferenciar su
          experiencia de la de otros usuarios, como, por ejemplo, el idioma, el
          número de resultados a mostrar cuando el usuario realiza una búsqueda,
          el aspecto o contenido del servicio en función del tipo de navegador a
          través del cual el usuario accede al servicio o de la región desde la
          que accede al servicio, etc. Si es el propio usuario quien elige esas
          características (por ejemplo, si selecciona el idioma de un sitio web
          clicando en el icono de la bandera del país correspondiente), las
          cookies estarán exceptuadas de las obligaciones del artículo 22.2 de
          la LSSI por considerarse un servicio expresamente solicitado por el
          usuario, y ello siempre y cuando las cookies obedezcan exclusivamente
          a la finalidad seleccionada. c) Cookies de análisis o medición: son
          aquellas que permiten al responsable de las mismas el seguimiento y
          análisis del comportamiento de los usuarios de los sitios web a los
          que están vinculadas, incluida la cuantificación de los impactos de
          los anuncios. La información recogida mediante este tipo de cookies se
          utiliza en la medición de la actividad de los sitios web, aplicación o
          plataforma, con el fin de introducir mejoras en función del análisis
          de los datos de uso que hacen los usuarios del servicio. d) Cookies de
          publicidad comportamental: son aquellas que almacenan información del
          comportamiento de los usuarios obtenida a través de la observación
          continuada de sus hábitos de navegación, lo que permite desarrollar un
          perfil específico para mostrar publicidad en función del mismo. Pág. 5
          3.3.- Según el plazo de tiempo que permanecen activadas a) Cookies de
          sesión: son aquellas diseñadas para recabar y almacenar datos mientras
          el usuario accede a una página web. Se suelen emplear para almacenar
          información que solo interesa conservar para la prestación del
          servicio solicitado por el usuario en una sola ocasión (por ejemplo,
          una lista de productos adquiridos) y desaparecen al terminar la
          sesión. b) Cookies persistentes: son aquellas en las que los datos
          siguen almacenados en el terminal y pueden ser accedidos y tratados
          durante un periodo definido por el responsable de la cookie, y que
          puede ir de unos minutos a varios años.
        </p>
        <h3>LISTADO DE COOKIES UTILIZADAS POR ESTE SITIO WEB</h3>
        <p className="paragraphs-policy">
          La utilización de las cookies ofrece ventajas en la prestación de
          servicios dentro de lo que se conoce como la sociedad de la
          información, puesto que, facilita al usuario la navegación y el acceso
          a los diferentes servicios que ofrece este sitio web; evita al usuario
          tener que configurar las características generales predefinidas cada
          vez que accede al sitio web; favorece la mejora del funcionamiento y
          de los servicios prestados a través de este sitio web, tras el
          correspondiente análisis de la información obtenida a través de las
          cookies instaladas. 
          
          En este caso, esta web no usa ningún tipo de cookies.
        </p>
      </div>
    </main>
  );
}

export default CookiesPolicy;
