import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useBookingContext } from "../context/bookingContext";

export default function PersonalDataForm() {
  const {
    introducedName,
    setIntroducedName,
    introducedEmail,
    setIntroducedEmail,
    introducedComments,
    setIntroducedComments,
    setFormValidation,
  } = useBookingContext();

  const validationSchema = Yup.object({
    introducedName: Yup.string().required("El nombre es requerido"),
    introducedEmail: Yup.string()
      .email("Formato de email inválido")
      .required("El email es requerido"),
    introducedComments: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      introducedName,
      introducedEmail,
      introducedComments,
    },
    validationSchema,
    onSubmit: (values) => {
      // Lógica para manejar el envío del formulario
      console.log("Formulario enviado con éxito:", values);
    },
  });

  const handleNameChange = (event) => {
    formik.handleChange(event);
    setIntroducedName(event.target.value);
  };

  const handleEmailChange = (event) => {
    formik.handleChange(event);
    setIntroducedEmail(event.target.value);
  };

  const handleCommentsChange = (event) => {
    formik.handleChange(event);
    setIntroducedComments(event.target.value);
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    // Validación de formulario
    const isFormValid = () => {
      return (
        formik.isValid &&
        formik.values.introducedName.trim() !== "" &&
        formik.values.introducedEmail.trim() !== ""
      );
    };

    // Actualizar el estado de validación en el contexto
    setFormValidation(isFormValid());
  }, [
    formik.isValid,
    formik.values.introducedName,
    formik.values.introducedEmail,
    setFormValidation,
  ]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* Nuevo estado para indicar si el formulario es válido */}
      <input type="hidden" name="isValid" value={formik.isValid} />
      <div className="space-y-8">
        <div className="border-b pb-12 px-4 pt-4">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Datos Personales
          </h2>

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="introducedName"
                  id="introducedName"
                  autoComplete="given-name"
                  className={`block w-full outline-none rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-900 sm:text-sm sm:leading-6 ${
                    formik.errors.introducedName ? "border-red-500" : ""
                  }`}
                  value={formik.values.introducedName}
                  onChange={handleNameChange}
                />
                {formik.errors.introducedName && (
                  <p className="mt-2 text-sm text-red-500">
                    {formik.errors.introducedName}
                  </p>
                )}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Correo electrónico
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="introducedEmail"
                  id="introducedEmail"
                  autoComplete="family-name"
                  className={`block w-full outline-none rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-900 sm:text-sm sm:leading-6 ${
                    formik.errors.introducedEmail ? "border-red-500" : ""
                  }`}
                  value={formik.values.introducedEmail}
                  onChange={handleEmailChange}
                />
                {formik.errors.introducedEmail && (
                  <p className="mt-2 text-sm text-red-500">
                    {formik.errors.introducedEmail}
                  </p>
                )}
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Indica cualquier solicitud especial que desees que nuestro
                equipo considere
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="introducedComments"
                  rows={3}
                  className={`block w-full outline-none rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-900 sm:text-sm sm:leading-6 ${
                    formik.errors.introducedComments ? "border-red-500" : ""
                  }`}
                  value={formik.values.introducedComments}
                  onChange={handleCommentsChange}
                />
                {formik.errors.introducedComments && (
                  <p className="mt-2 text-sm text-red-500">
                    {formik.errors.introducedComments}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
    
        <div className="border-b border-gray-900/10 pb-12 px-8"> 
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Notificacions
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Queremos mantenerte informado de las últimas novedades y ofertas
            exclusivas. Para personalizar tu experiencia, te ofrecemos la opción
            de recibir notificaciones por correo electrónico. Si deseas estar al
            tanto de nuestras actualizaciones y promociones, selecciona la
            opción "Aceptar". Si prefieres no recibir correos electrónicos,
            elige "Rechazar". Tus preferencias son importantes para nosotros.
          </p>

          <div className="mt-10 space-y-10">
            <fieldset>
              <legend className="text-sm font-semibold leading-6 text-gray-900">
                Por correo electrónico
              </legend>
              <div className="mt-6 space-y-6">
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="comments"
                      name="comments"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-neutral-900 focus:ring-neutral-900"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-900"
                    >
                      Acceptar
                    </label>
                    <p className="text-gray-500">
                      Quiero recibir notificaciones por email de este local.
                    </p>
                  </div>
                </div>
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="candidates"
                      name="candidates"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-neutral-900 focus:ring-neutral-900"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="candidates"
                      className="font-medium text-gray-900"
                    >
                      Rechazar
                    </label>
                    <p className="text-gray-500">
                      No quiero recibir notificaciones por email de este local.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </form>
  );
}
