import React from "react";
import BasketButton from "./basketButton";
import { useBookingContext } from "../context/bookingContext";
import {
  UserCircleIcon,
  CalendarDaysIcon,
  ClockIcon,
  BuildingStorefrontIcon,
} from "@heroicons/react/24/outline";

export default function BasketSummary() {
  const {
    currentService,
    selectedDate,
    selectedProfessional,
    selectedHour,
    openBusiness,
  } = useBookingContext();

  // Función para formatear la fecha
  const formatDate = (date) => {
    if (!date) return null;

    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const dateFormatter = new Intl.DateTimeFormat("es-ES", options);
    return dateFormatter.format(date);
  };

  return (
    <div className="divide-y space-y-3 m-4 divide-gray-200 dark:divide-gray-700">
      <div className="summary">
        <h2 className="text-xl font-bold pb-3">Tú cesta</h2>
        {openBusiness.name && (
          <p className="flex text-sm items-center font-semibold pt-2">
            <BuildingStorefrontIcon className="w-6 h-6 me-3 text-neutral-900" />
            <span>{openBusiness.name}</span>
          </p>
        )}
        {selectedProfessional && (
          <p className="flex text-sm items-center font-normal pt-2">
            <UserCircleIcon className="w-6 h-6 me-3 text-neutral-900" />
            <span>{selectedProfessional}</span>
          </p>
        )}
        {selectedDate && (
          <p className="flex text-sm items-center font-normal pt-2">
            <CalendarDaysIcon className="w-6 h-6 me-3 text-neutral-900" />
            <span>{formatDate(selectedDate)}</span>
          </p>
        )}
        {selectedHour && (
          <p className="flex text-sm items-center font-normal pt-2">
            <ClockIcon className="w-6 h-6 me-3 text-neutral-900" />
            <span>{selectedHour}</span>
          </p>
        )}
      </div>
      <div className="services pt-2">
        <ul className="">
          <li className="sm:pt-1">
            <div className="flex items-center space-x-4 rtl:space-x-reverse">
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                  {currentService.name}
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                {currentService.price + " €"}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="price-total pt-0">
        <ul className="">
          <li className="sm:pt-1">
            <div className="flex items-center space-x-4 rtl:space-x-reverse">
              <div className="flex-1 min-w-0">
                <p className="text-sm font-bold text-gray-900 truncate dark:text-white">
                  Total
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                {currentService.price + " €"}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <BasketButton />
    </div>
  );
}
