import "twix";
import { SRV_URL } from "../utils/constants";

/**
 * Clase encargada de manejar la API del servidor. Esta clase oferece diferentes
 * funciones para añadir, modificar, y eliminar una reserva. Además proporciona
 * una función para cargar todas las reservas disponibles para un negocio.
 */
export default class ServerAPI {
  constructor(api) {
    this.api = api;
  }

  /**
   * Esta función se encarga de enviar una solicitud al backend para enviar un email al correo de reservatucita.com
   * @param {*} data, datos del formulario, como email, nombre y mensaje a enviar
   * reserva, como fecha, hora, o nombre del cliente.
   * @returns, devuelve la respuesta del servidor.
   */
  async sendEmail(data) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      let response = await fetch(SRV_URL + "send-email", requestOptions);
      console.log("Respuesta del servidor en sendEmail:", response);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Esta función se encarga de enviar una solicitud al backend para crear una
   * reserva.
   * @param {*} reservation, es un objeto que contiene todos los datos de una
   * reserva, como fecha, hora, o nombre del cliente.
   * @param {*} serviceDuration, la duración del servicio deseado, es un valor
   * predefinido para cada tipo de servicio y se obtiene de la configuración.
   * @returns, devuelve la respuesta del servidor.
   */
  async blockTimeSlot(
    reservation,
    serviceDuration,
    servicePrice,
    bussinessID,
    selectedProfessionalId
  ) {
    // Obtener el token del almacenamiento local
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`, // Incluir el token en las cabeceras
        "Content-Type": "application/json", // Agregar el Content-Type
      },
      body: JSON.stringify({
        userService: reservation.getService(),
        userName: reservation.getUserName(),
        userEmail: reservation.getUserEmail(),
        userSelectedDate: reservation.getDate(),
        userSelectedHour: reservation.getHour(),
        isPaid: reservation.getIsPaid(),
        serviceDuration: serviceDuration,
        servicePrice: servicePrice,
        bussinessID: bussinessID,
        selectedProfessionalId: selectedProfessionalId,
      }),
    };

    return await fetch(SRV_URL + "blockTimeslot", requestOptions);
  }

  /**
   * Esta función se encarga de enviar una solicitud al backend para modificar
   * una reserva.
   * @param {*} reservation, es un objeto que contiene todos los datos de una
   * reserva, como fecha, hora, o nombre del cliente.
   */
  async confirmReservation(
    reservationId,
    bussinessID,
    selectedProfessionalId,
    selectedProfessional,
    introducedName,
    introducedEmail,
    selectedService,
    servicePrice
  ) {
    // Obtener el token del almacenamiento local
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`, // Incluir el token en las cabeceras
        "Content-Type": "application/json", // Agregar el Content-Type
      },
      body: JSON.stringify({
        reservationId: reservationId,
        bussinessID: bussinessID,
        selectedProfessionalId: selectedProfessionalId,
        selectedProfessional: selectedProfessional,
        introducedName: introducedName,
        introducedEmail: introducedEmail,
        selectedService: selectedService,
        servicePrice: servicePrice,
      }),
    };

    try {
      let response = await fetch(
        SRV_URL + "confirmreservation",
        requestOptions
      );
      console.log("Respuesta del servidor en confirmreservation:", response);

      return response; // Retorna la respuesta del servidor
    } catch (error) {
      throw error; // Lanza el error para ser manejado por la función llamadora
    }
  }

  /**
   * Esta función se encarga de enviar una solicitud al backend para modificar
   * una reserva.
   * @param {*} reservation, es un objeto que contiene todos los datos de una
   * reserva, como fecha, hora, o nombre del cliente.
   */
  async preConfirmReservation(
    reservationId,
    bussinessID,
    selectedProfessionalId,
    selectedProfessional,
    introducedName,
    introducedEmail,
    selectedService,
    servicePrice
  ) {
    // Obtener el token del almacenamiento local
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`, // Incluir el token en las cabeceras
        "Content-Type": "application/json", // Agregar el Content-Type
      },
      body: JSON.stringify({
        reservationId: reservationId,
        bussinessID: bussinessID,
        selectedProfessionalId: selectedProfessionalId,
        selectedProfessional: selectedProfessional,
        introducedName: introducedName,
        introducedEmail: introducedEmail,
        selectedService: selectedService,
        servicePrice: servicePrice,
      }),
    };

    try {
      let response = await fetch(
        SRV_URL + "preconfirmreservation",
        requestOptions
      );
      console.log("Respuesta del servidor en preconfirmreservation:", response);

      return response; // Retorna la respuesta del servidor
    } catch (error) {
      throw error; // Lanza el error para ser manejado por la función llamadora
    }
  }

  /**
   * Esta función se encarga de enviar una solicitud al backend para eliminar
   * una reserva.
   * @param {*} reservationId, identificador de la reserva a eliminar
   */
  async deleteReservation(reservationId, bussinessID, selectedProfessionalId) {
    // Obtener el token del almacenamiento local
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`, // Incluir el token en las cabeceras
        "Content-Type": "application/json", // Agregar el Content-Type
      },
      body: JSON.stringify({
        reservationId: reservationId,
        bussinessID: bussinessID,
        selectedProfessionalId: selectedProfessionalId,
      }),
    };
    try {
      let response = await fetch(
        SRV_URL + "deletereservation",
        requestOptions
      ).then((response) => response.json());
      console.log("Respuesta del servidor en modifyReservation:", response);

      return response; // Retorna la respuesta del servidor
    } catch (error) {
      throw error; // Lanza el error para ser manejado por la función llamadora
    }
  }

  /**
   * Esta función se encarga de cargar las franjas horarias disponibles de un
   * negocio en concreto.
   * @param {*} nearestReservationHours, número de dias a partir de los cuales
   * se puede reservar
   * @param {*} farthestReservationDays, número de dias hasta los cuales se puede
   * realizar un reserva.
   * @returns, devuelve la respuesta del servidor.
   */
  async initServer(
    nearestReservationHours,
    farthestReservationDays,
    bussinessID
  ) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        frontendId: "reservatucita.com_frontend",
        nearestReservationHours: nearestReservationHours,
        farthestReservationDays: farthestReservationDays,
        bussinessID: bussinessID,
      }),
    };

    try {
      let response = await fetch(SRV_URL + "mount", requestOptions).then(
        (response) => response.json()
      );
      console.log("Respuesta del servidor en initServer:", response);
      localStorage.setItem("token", response.token);

      return response; // Retorna la respuesta del servidor
    } catch (error) {
      throw error; // Lanza el error para ser manejado por la función llamadora
    }
  }
}
