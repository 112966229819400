import { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogTitle,
} from "@headlessui/react";
import { useBookingContext } from "../context/bookingContext";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

export default function GalleryModal({ open, onClose }) {
  const { openBusiness } = useBookingContext();
  const [isOpen, setIsOpen] = useState(open);
  const cancelButtonRef = useRef(null);
  const images = openBusiness.images;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsOpen(false);
      onClose();
    }
  };

  return (
    <Dialog
      className="relative z-10 rounded"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      initialFocus={cancelButtonRef}
      onKeyDown={handleKeyDown}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 flex items-center justify-center p-4 pt-8">
        <div className="relative w-full max-w-4xl bg-white rounded-lg shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
          <div className="bg-gray-100 px-4 py-3 flex justify-between items-center rounded">
            <DialogTitle
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Fotos
            </DialogTitle>
            <button
              type="button"
              className="text-gray-700 hover:text-black"
              onClick={() => {
                setIsOpen(false);
                onClose();
              }}
              ref={cancelButtonRef}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4 rounded">
            <div className="relative overflow-hidden transition-all max-h-[70vh]">
              <div className="flex justify-center items-center">
                <button
                  className="text-white bg-gray-800 bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none absolute left-4 top-1/2 transform -translate-y-1/2 z-10"
                  onClick={prevImage}
                >
                  <ChevronLeftIcon className="h-6 w-6" />
                </button>
                <div className="relative rounded">
                <div className="absolute inset-x-0 top-4 bg-black bg-opacity-50 text-white text-center py-1">
                    Imagen {currentIndex + 1} de {images.length}
                  </div>
                  <img
                    className="h-auto max-w-full rounded mx-auto pt-4"
                    src={require(`../assets/${images[currentIndex]}`)}
                    alt={`Imagen ${currentIndex + 1}`}
                    loading="lazy"
                    onClick={() =>
                      window.open(
                        require(`../assets/${images[currentIndex]}`),
                        "_blank"
                      )
                    }
                  />
                </div>
                <button
                  className="text-white bg-gray-800 bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none absolute right-4 top-1/2 transform -translate-y-1/2 z-10"
                  onClick={nextImage}
                >
                  <ChevronRightIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
