export default class Reservation {
    constructor(service="", userName="", userEmail="", date="", hour="", isPaid=false, reservationId="") {
        this.service = service
        this.userName = userName
        this.userEmail = userEmail
        this.date = date
        this.hour = hour
        this.isPaid = isPaid
        this.reservationId = reservationId
        this.reservationStartTime = this.date + " " + this.hour
    }

    getService() {
        return this.service
    }

    setService(service) {
        this.service = service
    }

    getUserName() {
        return this.userName
    }

    setUserName(userName) {
        this.userName = userName
    }

    getUserEmail() {
        return this.userEmail
    }

    setUserEmail(userEmail) {
        this.userEmail = userEmail
    }

    getDate() {
        return this.date
    }

    setDate(date) {
        this.date = date
    }

    getHour() {
        return this.hour
    }

    setHour(hour) {
        this.hour = hour
    }

    getIsPaid() {
        return this.isPaid
    }

    setIsPaid(isPaid) {
        this.isPaid = isPaid
    }

    getReservationId(){
        return this.reservationId
    }

    setReservationId(reservationId){
        this.reservationId = reservationId
    }

    getReservationStartTime(){
        return this.reservationStartTime
    }

    setReservationStartTime(reservationStartTime){
        this.reservationStartTime = reservationStartTime
    }
}