import React from "react";
import DateAndTime from "./dateAndTime";
import PersonalDataForm from "./personalDataForm";
import { useBookingContext } from "../context/bookingContext";

export default function BookingForm() {
  const { activeStep } = useBookingContext();

  return (
    <>
      {activeStep === 0 ? <DateAndTime /> : <PersonalDataForm />}
    </>
  );
}
