import React, { useState, useEffect } from "react";
import { useBookingContext } from "../context/bookingContext";

export default function DatePicker() {
  const {
    selectedDate,
    handleSelectedDateChange,
    selectedProfessional,
    toggleDatePicker,
  } = useBookingContext();
  const currentDate = new Date();
  const [hideWeeks, setHideWeeks] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const startingDay =
    new Date(currentYear, currentMonth, 1).getDay() === 0
      ? 6
      : new Date(currentYear, currentMonth, 1).getDay() - 1;

  const monthsInSpanish = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiempre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const renderWeeks = () => {
    const calendarDays = [];

    for (let day = 1 - startingDay; day <= daysInMonth; day++) {
      calendarDays.push(new Date(currentYear, currentMonth, day));
    }

    return (
      <table className="w-full content-center table-fixed">
        <thead>
          <tr className="w-full">
            {Array.from({ length: 7 }).map((_, index) => (
              <th
                key={index}
                className="p-2 text-center font-bold"
                style={{ border: "none" }}
              >
                {calendarDays[index]
                  ?.toLocaleDateString("es-ES", {
                    weekday: "short",
                  })
                  .charAt(0)
                  .toUpperCase()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: Math.ceil(calendarDays.length / 7) }).map(
            (_, rowIndex) => renderWeek(rowIndex, calendarDays)
          )}
        </tbody>
      </table>
    );
  };

  const renderWeek = (rowIndex, calendarDays) => {
    const weekClass =
      rowIndex === 0 ? "first-week" : hideWeeks ? "hidden" : "week-show";

    return (
      <tr key={rowIndex} className={weekClass}>
        {Array.from({ length: 7 }).map((_, colIndex) =>
          renderDay(rowIndex, colIndex, calendarDays)
        )}
      </tr>
    );
  };

  const renderDay = (rowIndex, colIndex, calendarDays) => {
    const index = rowIndex * 7 + colIndex;
    const date = calendarDays[index];
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 30);
    
    const isWithin30Days = date && date >= currentDate && date <= futureDate;
    
    const isClickable = date && selectedProfessional && isWithin30Days;
    const isSelected =
      date &&
      selectedDate &&
      date.toDateString() === selectedDate.toDateString();

    return (
      <td
        key={colIndex}
        className={`p-2 relative border ${
          date && date.getMonth() === currentMonth && isWithin30Days 
            ? `cursor-pointer ${
                isSelected
                  ? "bg-accent-normal text-white rounded drop-shadow-sm"
                  : "hover:bg-gray-100"
              }`
            : "text-gray-300"
        }`}
        onClick={() =>
          isClickable && date && selectedProfessional && handleSelectedDateChange(date)
        }
      >
        <div className="day">
          <div
            className={`flex items-center justify-center ${
              date && date.getMonth() !== currentMonth ||  !isWithin30Days ? "text-gray-500" : ""
            }`}
          >
            {date ? date.getDate() : ""}
          </div>
        </div>
      </td>
    );
  };

  const toggleCalendar = () => {
    setHideWeeks(!hideWeeks);
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth + 1) % 12);
    setCurrentYear((prevYear) =>
      currentMonth === 11 ? prevYear + 1 : prevYear
    );
  };

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1));
    setCurrentYear((prevYear) =>
      currentMonth === 0 ? prevYear - 1 : prevYear
    );
  };

  return (
    <div className="w-full text-center">
      <div className="mb-2 flex bg-accent-normal rounded-t-md items-center justify-between">
        <button
          onClick={handlePrevMonth}
          className="text-white font-medium pl-3 py-3"
        >
          Previo
        </button>
        <div className="flex items-center">
          <p className="text-white font-medium">
            {`${monthsInSpanish[currentMonth]}`}
          </p>
        </div>
        <button
          onClick={handleNextMonth}
          className="text-white font-medium pr-3 py-3"
        >
          Siguiente
        </button>
      </div>
      {renderWeeks()}
    </div>
  );
}
