import React from "react";
import { useBookingContext } from "../context/bookingContext";

export default function BarberShopDetails() {
  const { openBusiness } = useBookingContext();

  return (
    <div>
      <div className="Badges">
        <span className="inline-flex items-center px-2 py-1 rounded-sm bg-neutral-800 mr-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10">
          ELEGANTE
        </span>
        <span className="inline-flex items-center px-2 py-1 rounded-sm bg-accent-hover text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10">
          DETALLE
        </span>
        <h2 className="font-semibold text-2xl py-4">{openBusiness.name}</h2>
      </div>
      <div className="flex flex-col space-y-4">
        {/* Tipo de barbería - Masculina */}
        <div className="pr-2 pt-2 rounded-md flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="black"
            className="w-8 h-8 text-black mr-2 bg-opacity-25 bg-accent-normal hover:bg-opacity-40 rounded-md p-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
            />
          </svg>
          <span>Masculina</span>
        </div>
        {/* Ubicación */}
        <div className="pr-2 pb-4 rounded-md flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="black"
            className="w-8 h-8 text-black mr-2 bg-accent-normal hover:bg-opacity-40 bg-opacity-25 rounded-md p-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          <span>{openBusiness.location_dir}</span>
        </div>
      </div>
      <div className="Description space-y-2 pt-4">
        {openBusiness.description.map((paragraph, index) => (
          <p className="paragraphs-citas" key={index}>
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
}
