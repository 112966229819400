import React from "react";
import BasketButton from "./basketButton";
import { useBookingContext } from "../context/bookingContext";

export default function DateAndTimeBasketOffCanvas() {
  const { activeStep, isContinueButtonActive, formValidation } = useBookingContext();

  const getOffcanvasText = () => {
    if (activeStep === 0 && isContinueButtonActive) {
      return { text: "Completa tus datos personales" };
    }

    if (activeStep === 1 && !formValidation) {
      return { text: "Finaliza la transacción para confirmar tu reserva" };
    }
  };

  const { text } = getOffcanvasText() || {};
  const hideOnSmallScreens = "md:hidden";
  const shouldHide = hideOnSmallScreens + (activeStep === 2 ? " hidden" : "");

  // Mostrar u ocultar el componente según las condiciones
  if ((activeStep === 0 && !isContinueButtonActive) || (activeStep === 1 && formValidation)) {
    return (
      <div
        id="drawer-bottom-example"
        className={`fixed grid grid-cols-2 gap-4 bottom-0 left-0 right-0 z-40 p-4 transition-transform bg-white dark:bg-gray-800 shadow-2xl ${shouldHide}`}
        tabIndex="-1"
        aria-labelledby="drawer-bottom-label"
      >
        <div className={`container flex py-2 items-center mx-auto w-full md:w-1/3 ${hideOnSmallScreens}`}>
          <p className="max-w-lg text-sm text-black dark:text-gray-400">{text}</p>
        </div>
        <div className="container flex py-2 items-center justify-end mx-auto w-full md:w-1/3">
          <BasketButton />
        </div>
      </div>
    );
  }

  return null; // Ocultar el componente si no se cumplen las condiciones
}
