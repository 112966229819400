export const REDSYS_PAY = "HMAC_SHA256_V1";
export const REDSYS_URL = "https://sis.redsys.es/sis/realizarPago";
export const SRV_URL = "https://tus-citas-es.onrender.com/reservar/";
export const BOOKING_FORM = 0;
export const PAYMENT_OK = 1;
export const PAYMENT_NOK = 2;
export const RES_CANCEL= 3;
export const PAYMENT_TIMEOUT = 300; //s
export const ERROR_NOK = {
  BUSY_EVENT: "BUSY_EVENT",
  NON_APPOINTMNETS: "NON_APPOINTMNETS",
  PAY_TIMEOUT: "PAY_TIMEOUT",
};