import React from "react";

function TermsAndConditions() {
  return (
    <main>
      <div className="container p-6 md:p-20 pt-28 space-y-6">
        <h1 className="text-xl">Condiciones de uso</h1>
        <p className="paragraphs-policy">
          Aviso legal para el Sitio Web Con la finalidad de dar cumplimiento al
          artículo 10 de la Ley 34/2002 de Servicios de la Sociedad de la
          Información y Comercio Electrónico, informamos al usuario de nuestros
          datos:
        </p>
        <ul>
            <li>
              Domicilio Social: RIO GLORIETA, 2 B - 43006 - TARRAGONA - TARRAGONA
            </li>
            <li> 
              Teléfono: 631490976 
            </li>
            <li> 
              e-Mail: SIMODYMA@GMAIL.COM 
            </li>
          </ul>
        <h2>Objeto</h2>
        <p className="paragraphs-policy">
          El prestador, responsable del sitio web, pone a disposición de los
          usuarios el presente documento con el que pretende dar cumplimiento a
          las obligaciones dispuestas en la Ley 34/2002, de Servicios de la
          Sociedad de la Información y del Comercio Electrónico (LSSI-CE), así
          como informar a todos los usuarios del sitio web respecto a cuáles son
          las condiciones de uso del sitio web. Toda persona que acceda a este
          sitio web asume el papel de usuario, comprometiéndose a la observancia
          y cumplimiento riguroso de las disposiciones aquí dispuestas, así como
          a cualquier otra disposición legal que fuera de aplicación. El
          prestador se reserva el derecho a modificar cualquier tipo de
          información que pudiera aparecer en el sitio web, sin que exista
          obligación de preavisar o poner en conocimiento de los usuarios dichas
          obligaciones, entendiéndose como suficiente con la publicación en el
          sitio web del prestador.
        </p>
        <h2>Responsabilidad</h2>
        <p className="paragraphs-policy">
          El prestador se exime de cualquier tipo de responsabilidad derivada de
          la información publicada en su sitio web, siempre que esta información
          haya sido manipulada o introducida por un tercero ajeno al mismo. El
          sitio web del prestador puede utilizar cookies (pequeños archivos de
          información que el servidor envía al ordenador de quien accede a la
          página) para llevar a cabo determinadas funciones que son consideradas
          imprescindibles para el correcto funcionamiento y visualización del
          sitio. Las cookies utilizadas en el sitio web tienen, en todo caso,
          carácter temporal con la única finalidad de hacer más eficaz su
          transmisión ulterior y desaparecen al terminar la sesión del usuario.
          En ningún caso se utilizarán las cookies para recoger información de
          carácter personal. Desde el sitio web del cliente es posible que se
          redirija a contenidos de terceros sitios web. Dado que el prestador no
          puede controlar siempre los contenidos introducidos por los terceros
          en sus sitios web, éste no asume ningún tipo de responsabilidad
          respecto a dichos contenidos. En todo caso, el prestador manifiesta
          que procederá a la retirada inmediata de cualquier contenido que
          pudiera contravenir la legislación nacional o internacional, la moral
          o el orden público, procediendo a la retirada inmediata de la
          redirección a dicho sitio web, poniendo en conocimiento de las
          autoridades competentes el contenido en cuestión. El prestador no se
          hace responsable de la información y contenidos almacenados, a título
          enunciativo pero no limitativo, en foros, chats, generadores de blogs,
          comentarios, redes sociales o cualquier otro medio que permita a
          terceros publicar contenidos de forma independiente en la página web
          del prestador. No obstante y en cumplimiento de lo dispuesto en el
          art. 11 y 16 de la LSSI-CE, el prestador se pone a disposición de
          todos los usuarios, autoridades y fuerzas de seguridad, y colaborando
          de forma activa en la retirada o en su caso bloqueo de todos aquellos
          contenidos que pudieran afectar o contravenir la legislación nacional,
          o internacional, derechos de terceros o la moral y el orden público.
          En caso de que el usuario considere que existe en el sitio web algún
          contenido que pudiera ser susceptible de esta clasificación, se ruega
          lo notifique de forma inmediata al administrador del sitio web. Este
          sitio web ha sido revisado y probado para que funcione correctamente.
          En principio, puede garantizarse el correcto funcionamiento los 365
          días del año, 24 horas al día. No obstante, el prestador no descarta
          la posibilidad de que existan ciertos errores de programación, o que
          acontezcan causas de fuerza mayor, catástrofes naturales, huelgas, o
          circunstancias semejantes que hagan imposible el acceso a la página
          web.
        </p>
        <h2>Propiedad Intelectual e industrial</h2>
        <p className="paragraphs-policy">
          El sitio web, incluyendo a título enunciativo pero no limitativo su
          programación, edición, compilación y demás elementos necesarios para
          su funcionamiento, los diseños, logotipos, texto y/o gráficos son
          propiedad del prestador o en su caso dispone de licencia o
          autorización expresa por parte de los autores. Todos los contenidos
          del sitio web se encuentran debidamente protegidos por la normativa de
          propiedad intelectual e industrial, así como inscritos en los
          registros públicos correspondientes. Independientemente de la
          finalidad para la que fueran destinados, la reproducción total o
          parcial, uso, explotación, distribución y comercialización, requiere
          en todo caso de la autorización escrita previa por parte del
          prestador. Cualquier uso no autorizado previamente por parte del
          prestador será considerado un incumplimiento grave de los derechos de
          propiedad intelectual o industrial del autor. Los diseños, logotipos,
          texto y/o gráficos ajenos al prestador y que pudieran aparecer en el
          sitio web, pertenecen a sus respectivos propietarios, siendo ellos
          mismos responsables de cualquier posible controversia que pudiera
          suscitarse respecto a los mismos. En todo caso, el prestador cuenta
          con la autorización expresa y previa por parte de los mismos. El
          prestador NO AUTORIZA expresamente a que terceros puedan redirigir
          directamente a los contenidos concretos del sitio web, debiendo en
          todo caso redirigir al sitio web principal del prestador. El prestador
          reconoce a favor de sus titulares los correspondientes derechos de
          propiedad industrial e intelectual, no implicando su sola mención o
          aparición en el sitio web la existencia de derechos o responsabilidad
          alguna del prestador sobre los mismos, como tampoco respaldo,
          patrocinio o recomendación por parte del mismo. Para realizar
          cualquier tipo de observación respecto a posibles incumplimientos de
          los derechos de propiedad intelectual o industrial, así como sobre
          cualquiera de los contenidos del sitio web, puede hacerlo a través del
          siguiente correo electrónico.
        </p>
        <h2>Veracidad de la información y menores de edad</h2>
        <p className="paragraphs-policy">
          Toda la información que facilita el Usuario tiene que ser veraz. A
          estos efectos, el Usuario garantiza la autenticidad de los datos
          comunicados a través de los formularios para la suscripción de los
          Servicios. Será responsabilidad del Usuario mantener toda la
          información facilitada a MOHAMED EL QAMAR permanentemente actualizada
          de forma que responda, en cada momento, a su situación real. En todo
          caso, el Usuario será el único responsable de las manifestaciones
          falsas o inexactas que realice y de los perjuicios que cause al
          prestador o a terceros. En cuando al uso de los servicios de este
          sitio web, los menores de edad tienen que obtener siempre previamente
          el consentimiento de los padres, tutores o representantes legales,
          responsables últimos de todos los actos realizados por los menores a
          su cargo. La responsabilidad en la determinación de contenidos
          concretos a los cuales acceden los menores corresponde a aquellos, es
          por eso que si acceden a contenidos no apropiados por Internet, se
          tendrán que establecer en sus ordenadores mecanismos, en particular
          programas informáticos, filtros y bloqueos, que permitan limitar los
          contenidos disponibles y, a pesar de que no sean infalibles, son de
          especial utilidad para controlar y restringir los materiales a los que
          pueden acceder los menores.
        </p>
        <h2>Obligación de hacer un uso correcto de la Web</h2>
        <p className="paragraphs-policy">
          El Usuario se compromete a utilizar la Web de conformidad a la Ley y
          al presente Aviso Legal, así como a la moral y a buenas costumbres. A
          tal efecto, el Usuario se abstendrá de utilizar la página con
          finalidades ilícitas o prohibidas, lesivas de derechos e intereses de
          terceros, o que de cualquier forma puedan dañar, inutilizar,
          sobrecargar, deteriorar o impedir la normal utilización de equipos
          informáticos o documentos, archivos y toda clase de contenidos
          almacenados en cualquier equipo informático del prestador. En
          particular, y a título indicativo pero no exhaustivo, el Usuario se
          compromete a no transmitir, difundir o poner a disposición de terceros
          informaciones, datos, contenidos, mensajes, gráficos, dibujos,
          archivos de sonido o imagen, fotografías, grabaciones, software y, en
          general, cualquier clase de material que: (a) sea contraria, desprecie
          o atente contra los derechos fundamentales y las libertades públicas
          reconocidas constitucionalmente, en tratados internacionales y otras
          normas vigentes; (b) induzca, incite o promueva actuaciones
          delictivas, denigrantes, difamatorias, violentas o, en general,
          contrarias a la ley, a la moral y al orden público; (c) induzca,
          incite o promueva actuaciones, actitudes o pensamientos
          discriminatorios por razón de sexo, raza, religión, creencias, edad o
          condición; (d) sea contrario al derecho al honor, a la intimidad
          personal o familiar o a la propia imagen de las personas; (e) de
          cualquier manera perjudique la credibilidad del prestador o de
          terceros; y (f) constituya publicidad ilícita, engañosa o desleal.
        </p>
        <h2>Ley Aplicable y Jurisdicción</h2>
        <p className="paragraphs-policy">
          Para la resolución de todas las controversias o cuestiones
          relacionadas con el presente sitio web o de las actividades en él
          desarrolladas, será de aplicación la legislación española, a la que se
          someten expresamente las partes, siendo competentes para la resolución
          de todos los conflictos derivados o relacionados con su uso los
          Juzgados y Tribunales de TARRAGONA.
        </p>
      </div>
    </main>
  );
}

export default TermsAndConditions;