import React, { useState, useEffect } from "react";
import DatePicker from "./datepicker";
import { useBookingContext } from "../context/bookingContext";

export default function DateAndTime() {
  const {
    selectedHour,
    setSelectedHour,
    serviceProfessionals,
    selectedProfessional,
    handleSelectedProfessionalChange,
    setIsContinueButtonActive,
    professionalAvailableHours,
  } = useBookingContext();

  const [activeButton, setActiveButton] = useState(null);

  const handleHourSelect = (index) => {
    setSelectedHour(professionalAvailableHours[index]);
    setActiveButton(index);
  };

  useEffect(() => {
    setIsContinueButtonActive(!(selectedProfessional && selectedHour)); // Asegura que es un booleano
  }, [selectedProfessional, selectedHour, setIsContinueButtonActive]);

  return (
    <ul
      className="relative m-0 w-full list-none overflow-hidden p-0 pb-10 transition-[height] duration-200 ease-in-out"
      data-te-stepper-init
      data-te-stepper-type="vertical"
    >
      <li
        data-te-stepper-step-ref
        className="relative h-fit after:absolute after:left-[2.45rem] after:top-[3.6rem] after:mt-px after:h-[calc(100%-2.45rem)] after:w-px after:bg-[#e0e0e0] after:content-[''] dark:after:bg-neutral-600 after:hidden after:md:block"
      >
        <div
          data-te-stepper-head-ref
          className="flex cursor-pointer items-center p-6 leading-[1.3rem] no-underline after:bg-[#e0e0e0] after:content-[''] hover:bg-[#f9f9f9] focus:outline-none dark:after:bg-neutral-600 dark:hover:bg-[#3b3b3b]"
        >
          <span
            data-te-stepper-head-icon-ref
            className="mr-3 h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-[#ebedef] text-sm font-medium text-[#40464f] hidden md:flex"
          >
            1
          </span>
          <span
            data-te-stepper-head-text-ref
            className="font-semibold after:absolute after:flex after:text-[0.8rem] after:content-[data-content] dark:text-neutral-300"
          >
            Por favor escoge un profesional
          </span>
        </div>
        <div
          data-te-stepper-content-ref
          className="transition-[height, margin-bottom, padding-top, padding-bottom] left-0 overflow-hidden pb-3 pr-6 pl-6 md:pl-[3.75rem] duration-300 ease-in-out"
        >
          <label
            htmlFor="professional"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Escoge un profesional que te atenderá durante toda tu instancia con
            nosotros
          </label>
          <div className="mt-2">
            <select
              id="professional"
              name="professional"
              autoComplete="professional"
              className="block w-full outline-none rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-900 sm:text-sm sm:leading-6"
              onChange={handleSelectedProfessionalChange}
              value={selectedProfessional}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Seleccione un profesional
              </option>
              {serviceProfessionals.map((professional, index) => (
                <option key={index} value={professional}>
                  {professional}
                </option>
              ))}
            </select>
          </div>
        </div>
      </li>

      <li
        data-te-stepper-step-ref
        className="relative h-fit after:absolute after:left-[2.45rem] after:top-[3.6rem] after:mt-px after:h-[calc(100%-2.45rem)] after:w-px after:bg-[#e0e0e0] after:content-[''] dark:after:bg-neutral-600 after:hidden after:md:block"
      >
        <div
          data-te-stepper-head-ref
          className="flex cursor-pointer items-center p-6 leading-[1.3rem] no-underline after:bg-[#e0e0e0] after:content-[''] hover:bg-[#f9f9f9] focus:outline-none dark:after:bg-neutral-600 dark:hover:bg-[#3b3b3b]"
        >
          <span
            data-te-stepper-head-icon-ref
            className="mr-3  h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-[#ebedef] text-sm font-medium text-[#40464f] hidden md:flex"
          >
            2
          </span>
          <span
            data-te-stepper-head-text-ref
            className="font-semibold after:absolute after:flex after:text-[0.8rem] after:content-[data-content] dark:text-neutral-300"
          >
            Ahora escoge un día y una hora
          </span>
        </div>
        <div
          data-te-stepper-content-ref
          className="transition-[height, margin-bottom, padding-top, padding-bottom] left-0 overflow-hidden py-2 pl-6 md:pl-[3.75rem] pr-6 duration-300 ease-in-out"
        >
          <div className="datepicker">
            <DatePicker />
          </div>
          <div className="bg-gray-50 mt-4">
            <p className="px-4 py-4">
              Aquí encontrarás las horas disponibles para reservar tu cita en la barbería. Elige el horario que más te convenga y procede con la reserva.
            </p>
            <ul className="grid grid-cols-2 sm:grid-cols-4 gap-4 px-4 pb-10">
              {professionalAvailableHours.map((hour, index) => (
                <li
                  key={index}
                  className={`flex items-center justify-center rounded border-2 border-white ${
                    activeButton === index
                      ? "outline-none border-accent-normal"
                      : ""
                  }`}
                  style={{ outline: "none" }}
                >
                  <button
                    className="bg-white text-black w-full py-4 border rounded drop-shadow-sm hover:drop-shadow-md focus:outline-none"
                    onClick={() => handleHourSelect(index)}
                  >
                    {hour}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </li>
    </ul>
  );
}
