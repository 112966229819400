import moment from "moment"
import 'moment/locale/es';

/**
 * Declarando la clase MyDate, la cual se encarga de formatear fechas, haciendo
 * uso de la libraría moment.
 */
export default class MyDate {
    constructor() {
        moment.locale('es')
    }
    
    /**
     * Función para formatear la fecha que se recibe por parámetro, devuelve la
     * misma fecha con formato largo.
     * @param {*} date, la fecha a formatear 
     * @returns fecha con formato largo, ej., 7 de enero 2022
     */
    dateLongFormat(date) {
        return moment(date).format('LL')
      }

    /**
     * Función para formatear la fecha que se recibe por parámetro, devuelve la
     * misma fecha con formato corto.
     * @param {*} date, la fecha a formatear 
     * @returns fecha con formato largo, ej., 07/01/2022
     */
    dateShortFormat(date){
        return moment(date).format("DD-MM-YYYY")
    }

    /**
     * Función para sumar x dias a la fecha de hoy. x = days que se recibe por
     * parámetro.
     * @param {*} days, los dias a sumar a la fecha de hoy
     * @returns objeto Date de hoy más x=days dias
     */
    dateAddDays(days){
        return moment().add(days, 'days')
    }

}