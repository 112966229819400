import React, { useEffect, useState } from "react";
import { useBookingContext } from "../context/bookingContext";
import PaymentForm from "./paymentForm";

const BasketButton = () => {
  const { activeStep, handleContinue, handleReview, handleConfirm, paymentEnabled, formValidation, isContinueButtonActive} = useBookingContext();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getButtonTextAndHandler = () => {
    switch (activeStep) {
      case 0:
        return { text: "Continuar", handler: handleContinue, disabled: isContinueButtonActive };

      case 1:
        return {
          text: isSmallScreen ? "Revisar" : !paymentEnabled ? "Confirmar" : "",
          handler: isSmallScreen ? handleReview : handleConfirm,
          disabled: !formValidation
        };

      case 2:
        return {
          text: isSmallScreen && !paymentEnabled ? "Confirmar" : "",
          handler: handleConfirm,
          disabled: !formValidation
        };

      default:
        return { text: "" };
    }
  };

  const { text, handler, disabled } = getButtonTextAndHandler();

  return (
    <>
      {text ? (
        <button
          type="button"
          onClick={activeStep < 3 ? handler : null}
          className={`text-white w-full bottom-0 bg-accent-normal hover:bg-accent-hover focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={disabled}
        >
          {text}
        </button>
      ) : (
        <PaymentForm />
      )}
    </>
  );
};

export default BasketButton;
