import React from "react";
import "./style/App.css";
import "./style/AppB.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import TermsAndConditions from "./pages/termsAndConditions";
import PrivacyPolicy from "./pages/privacyPolicy";
import CookiesPolicy from "./pages/cookiesPolicy";
import About from "./pages/about";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import PageNotFound from "./pages/notFound";
import { BookingProvider } from "./context/bookingContext";
import BarberShopPage from "./pages/BarberShopPage";
import {
  PAYMENT_OK,
  PAYMENT_NOK,
  BOOKING_FORM,
  RES_CANCEL,
} from "./utils/constants";

let config = require("./CLIENT_SIDE_CONFIG.json");
let businesses = config.bussinesses;

/**
 * Aplicación principal, contiene todas las routes con las paginas correspondientes
 * a mostrar en cada momento. Consiste de tres partes, un header-navbar, body o
 * cuerpo de la app y un footer o pie de página. El header y el footer son estaticos
 * mientras que el cuerpo de la app cambia acorde al recurso solicitado por el usuario.
 * @returns
 */
function App() {
  return (
    <div className="App font-ralewayx bg-gray-100">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home businesses={businesses} />} />
          <Route path="/inicio" element={<Home businesses={businesses} />} />
          {businesses.map((business, index) => (
            <React.Fragment key={index}>
              <Route
                path={business.relURL}
                element={
                  <BookingProvider
                    pageToShow={BOOKING_FORM}
                    initialBusiness={business}
                  >
                    <BarberShopPage />{" "}
                  </BookingProvider>
                }
              />
              <Route
                path={business.relURL + "/anularcita/*"}
                element={
                  <BookingProvider
                    pageToShow={RES_CANCEL}
                    initialBusiness={business}
                  >
                    <BarberShopPage />{" "}
                  </BookingProvider>
                }
              />
              <Route
                path={business.relURL + "/pagorealizado/*"}
                element={
                  <BookingProvider
                    pageToShow={PAYMENT_OK}
                    initialBusiness={business}
                  >
                    <BarberShopPage />{" "}
                  </BookingProvider>
                }
              />
              <Route
                path={business.relURL + "/pagonorealizado/*"}
                element={
                  <BookingProvider
                    pageToShow={PAYMENT_NOK}
                    initialBusiness={business}
                  >
                    <BarberShopPage />{" "}
                  </BookingProvider>
                }
              />
            </React.Fragment>
          ))}
          <Route path="/condiciones" element={<TermsAndConditions />} />
          <Route path="/privacidad" element={<PrivacyPolicy />} />
          <Route path="/cookies" element={<CookiesPolicy />} />
          <Route path="/sobrenosotros" element={<About />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
        <hr className="featurette-divider" style={{ visibility: "hidden" }} />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
