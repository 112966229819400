import moment from "moment";
import { ERROR_NOK } from "./constants";
import "twix";

/**
 * Esta función se encarga de calcular las horas disponibles para un servicio
 * en concreto.
 * @param {*} res, respuesta del servidor con todas las horas disponibles.
 * @param {*} nearestReservationHours, horas a partir de las cuales se puede reservar
 * @param {*} farthestReservationDays, dias hasta los cuales se puede reservar
 * @param {*} reservationFreqMinutes, minutos cada cuanto se permite reservar
 * @param {*} serviceDuration, duración del servicio en minutos
 * @param {*} userSelectedDate, la fecha seleccionada por el cliente
 * @returns, devuelve las citas disponibles para el servicio indicado.
 */
export function availableHours(
  res,
  nearestReservationHours,
  farthestReservationDays,
  reservationFreqMinutes,
  serviceDuration,
  userSelectedDate
) {
  let today = new Date(); // fecha y hora actuales
  let availableAppointments = [];
  let appointmentAvailable = false;
  let t1;
  let t2;
  const reservationFreqMs = 1000 * 60 * reservationFreqMinutes;
  let nearestReservationDays = (nearestReservationHours / 24) >> 0;
  let start = new Date(userSelectedDate);
  let allAvailable = true;
  
//modificar este código para que las horas disponibles estén como mínimo a distancia 
//de las reservas existentes, como para que quepa omo mínimo  la reserva con menor tiempo. ejemplo, si el tiempo entre
// reservas es 5 min, que no queden 5 min entre la reserva existente y la nueva, sino 15min (duración de la menor reserva)
//ver si hacer para reservas antes y después.


  if (today.toDateString() === start.toDateString()) {
    // si empieza hoy, Redondeamos según la frecuencia de reserva 5 o 10 o
    // 15min, para que no coja los minutos de ahora
    start.setTime(
      Math.ceil(today.getTime() / reservationFreqMs) * reservationFreqMs
    );
    if (nearestReservationDays === 0) {
      // Si se puede reservar el mismo día, se añade las horas de margen según
      // nearestReservationHours, sino se quedan como 00:00:00
      start.setTime(start.getTime() + nearestReservationHours * 60 * 60 * 1000);
    }
  }

  let nearestReservationDate = new Date();
  // Sólo nos quedamos con el día, las horas a 00:00:00 para el siguiente paso
  nearestReservationDate.setHours(0, 0, 0, 0);
  nearestReservationDate.setDate(today.getDate() + nearestReservationDays);

  let farthestReservationDate = new Date();
  // Sólo nos quedamos con el día, las horas a 00:00:00 para el siguiente paso
  farthestReservationDate.setHours(0, 0, 0, 0);
  farthestReservationDate.setDate(today.getDate() + farthestReservationDays);

  if (
    start.getTime() >= nearestReservationDate.getTime() &&
    start.getTime() <= farthestReservationDate.getTime()
  ) {
    // Si la fecha seleccionada entra dentro del rango de fechas permitido
    // para reservar
    let end = new Date(start.valueOf());
    end.setDate(end.getDate() + 1);

    // Para loop por horas (tiempo de reserva)
    let checkedPeriod = moment(start)
      .twix(end)
      .iterate(reservationFreqMinutes, "minutes");
    while (checkedPeriod.hasNext()) {
      appointmentAvailable = true;
      let nextHour = new Date(checkedPeriod.next().format());
      let until = new Date(nextHour.valueOf());
      t1 = moment(nextHour).twix(
        until.setMinutes(until.getMinutes() + serviceDuration)
      ); // Periodo de una reserva, inicio y fin
      for (let index = 0; index < res.length; index++) {
        t2 = moment(res[index].start.dateTime).twix(res[index].end.dateTime);
        if (t1.overlaps(t2) === true) {
          appointmentAvailable = false;
        }
      }
      if (appointmentAvailable === true) {
        nextHour = new Date(nextHour).toLocaleString("en-GB", {
          timeZone: "Europe/Madrid",
        });
        availableAppointments.push(nextHour);
        allAvailable = true;
      }
    }
  }
  if (allAvailable === false) {
    // Algo pasa aquí, ver porque siempre devuelve error :)
    return ERROR_NOK["NON_APPOINTMENTS"];
  } else {
    return availableAppointments;
  }
}
