import React, { useState, useEffect, useMemo } from "react";
import ServerAPI from "../api/api";
import Button from "@mui/material/Button";
import cancel_pending from "../assets/global/cancel_pending.svg";
import cancel_ok from "../assets/global/cancel_ok.svg";

/**
 * Componente funcional encargado de manejar el proceso de cancelación de una cita.
 */
const CancelReserve = () => {
  const [cancelPending, setCancelPending] = useState(true);
  const [bs, setBs] = useState("");
  const [clid, setClid] = useState("");
  const [ed, setEd] = useState("");
  const api = useMemo(() => new ServerAPI("Server"), []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    setBs(urlParams.get("bs"));
    setClid(urlParams.get("clid"));
    setEd(urlParams.get("ed"));
  }, []);

  const handleQuery = () => {
    api.deleteReservation(ed, bs, clid);
    setCancelPending(false);
  };

  const cancelPendingContent = (
    <main className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <img src={cancel_pending} width="200" height="200" alt="cancel" className="mx-auto mb-4" />
        <p className="text-lg font-bold text-red-600 mb-2">
          Seguro que quieres anular tu cita {ed}?
        </p>
        <p className="mb-4">
          ¡Por favor, ten en cuenta que tu cita se eliminará definitivamente!
        </p>
        <Button
          style={{
            borderRadius: 0,
            color: "#dc3545",
            borderColor: "#dc3545",
          }}
          variant="outlined"
          size="medium"
          color="success"
          onClick={handleQuery}
        >
          Anular cita
        </Button>
      </div>
    </main>
  );

  const cancelOkContent = (
    <main className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <img src={cancel_ok} width="200" height="200" alt="cancel" className="mx-auto mb-4" />
        <p className="text-lg font-bold text-green-600 mb-2">
          Cita eliminada correctamente!
        </p>
        <p className="mb-4">
          ¡Cita eliminada correctamente, para cualquier duda, no dude en
          contactarnos!
        </p>
        <Button
          style={{
            borderRadius: 0,
            color: "#198754",
            borderColor: "#198754",
          }}
          variant="outlined"
          size="medium"
          color="success"
          href={"https://www.reservatucita.com"}
        >
          Volver
        </Button>
      </div>
    </main>
  );

  return cancelPending ? cancelPendingContent : cancelOkContent;
};

export default CancelReserve;
