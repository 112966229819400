import React from "react";
import CountdownTimer from "./countDown";
import { useBookingContext } from "../context/bookingContext";

export default function StepperHorizontal() {
  const {
    activeStep,
    setActiveStep,
    steps,
    isContinueButtonActive,
    formValidation,
    handleBookingClose,
    cleanBookingData,
    deleteReservation,
    cancelBookingTimeout,
  } = useBookingContext();

  const handleStepClick = (stepIndex) => {
    // Verificar condiciones antes de actualizar el estado
    if (
      stepIndex === 0 ||
      (stepIndex === 1 && !isContinueButtonActive) ||
      (stepIndex === 2 && formValidation)
    ) {
      setActiveStep(stepIndex);
    }
    if (activeStep === 1 && stepIndex === 0) {
      deleteReservation();
      cleanBookingData();
      cancelBookingTimeout();
    }
  };

  return (
    <div className="flex justify-between mx-auto w-full lg:w-5/6 xl:w-3/4 2xl:w-3/5 py-3 md:py-2">
      <div className="steps">
        <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
          {steps.map((step, index) => (
            <li
              key={index}
              onClick={() => handleStepClick(index)}
              className={`flex items-center space-x-2 cursor-pointer transition-opacity ${
                index === activeStep
                  ? "text-neutral-900 dark:text-blue-500 opacity-100"
                  : index === 2 && "md:hidden"
                  ? "md:hidden"
                  : "text-gray-500 opacity-50"
              }`}
            >
              {index >= 1 && (
                <svg
                  className={`w-3 h-3 rtl:rotate-180 me-1 ${
                    index === activeStep ? "text-gray-500" : ""
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 12 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m7 9 4-4-4-4M1 9l4-4-4-4"
                  />
                </svg>
              )}
              <span
                className={`flex items-center justify-center w-6 h-6 text-xs border border-accent-normal rounded-full dark:border-blue-500 ${
                  index === activeStep
                    ? "bg-accent-normal text-white"
                    : "bg-white text-gray-500"
                }`}
              >
                {index + 1}
              </span>
              <div
                className={`hidden md:block ${
                  index === activeStep
                    ? "text-neutral-900 dark:text-blue-500 font-semibold opacity-100"
                    : "text-gray-500 opacity-70"
                } transition-opacity`}
              >
                {step.label}
              </div>
            </li>
          ))}
        </ol>
      </div>
      <div class="controls grid grid-cols-2 gap-4 justify-items-end items-center">
        <div
          className={`${
            activeStep === 1 || activeStep === 2 ? "visible" : "invisible"
          }`}
        >
          <CountdownTimer />
        </div>
        <div>
          <button
            className="text-black text-xl font-semibold pr-5 hover:text-red-500 transition-colors"
            onClick={handleBookingClose}
          >
            X
          </button>
        </div>
      </div>
    </div>
  );
}
