import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { useBookingContext } from "../context/bookingContext";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Importa el ícono predeterminado de Leaflet
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

// Crea un nuevo ícono usando el ícono predeterminado y su sombra
const defaultIcon = new L.Icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41], // Ajusta el ancla del ícono según sea necesario
  popupAnchor: [1, -34], // Ajusta el ancla del ícono según sea necesario
  iconSize: [25, 41], // Ajusta el tamaño del ícono según sea necesario
  shadowSize: [41, 41], // Ajusta el tamaño de la sombra según sea necesario
});

export default function MapMarker() {
  const { openBusiness } = useBookingContext();
  const barberLocation = openBusiness.barberLocation;


  return (
    <div className="rounded-sm" style={{ position: "relative", zIndex: 0 }}>
      <h2 className="font-bold py-3">Ubicación</h2>
      <hr className="col-span-12 border-t border-gray-300 my-0 py-2" />
      <MapContainer
        center={barberLocation}
        zoom={13}
        style={{ height: "400px", width: "100%", zIndex: 1 }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={barberLocation} icon={defaultIcon}></Marker>
      </MapContainer>
    </div>
  );
}
