import React, { useState } from "react";
import GalleryModal from "../components/galleryModal";
import { useBookingContext } from "../context/bookingContext";

export default function Gallery() {
  const [modalOpen, setModalOpen] = useState(false);
  const { openBusiness } = useBookingContext();
  const images = openBusiness.images;

  const handleOpenGallery = () => {
    setModalOpen(true);
  };

  return (
    <div className="flex flex-wrap gap-2 bg-white">
      <h2 className="w-full md:w-2/3 font-bold pt-2">Galería</h2>
      <hr className="w-full border-t border-gray-300 my-0" />
      <div className="grid grid-cols-5 grid-rows-2 gap-1">
        <div>
          {" "}
          <div className={`relative w-full`} onClick={handleOpenGallery}>
            <img
              className="h-auto w-full object-cover cursor-pointer rounded"
              src={require("../assets/" + images[0])}
              alt=""
            />
          </div>
        </div>
        <div>
          {" "}
          <div className={`relative w-full`} onClick={handleOpenGallery}>
            <img
              className="h-auto w-full object-cover cursor-pointer rounded"
              src={require("../assets/" + images[1])}
              alt=""
            />
          </div>
        </div>
        <div className="col-start-1 row-start-2">
          {" "}
          <div className={`relative w-full`} onClick={handleOpenGallery}>
            <img
              className="h-auto w-full object-cover cursor-pointer rounded"
              src={require("../assets/" + images[2])}
              alt=""
            />
          </div>
        </div>
        <div className="col-start-2 row-start-2">
          {" "}
          <div className={`relative w-full`} onClick={handleOpenGallery}>
            <img
              className="h-auto w-full object-cover cursor-pointer rounded"
              src={require("../assets/" + images[5])}
              alt=""
            />
          </div>
        </div>
        <div className="col-span-2 row-span-3 col-start-3 row-start-1 pb-1">
          {" "}
          <div className={`relative w-full`} onClick={handleOpenGallery}>
            <img
              className="h-auto w-full object-cover cursor-pointer rounded"
              src={require("../assets/" + images[1])}
              alt=""
            />
          </div>
        </div>
        <div className="col-start-5 row-start-1">
          {" "}
          <div className={`relative w-full`} onClick={handleOpenGallery}>
            <img
              className="h-auto w-full object-cover cursor-pointer rounded"
              src={require("../assets/" + images[5])}
              alt=""
            />
          </div>
        </div>
        <div className="col-start-5 row-start-2 relative">
          <div className="relative w-full">
            <img
              className="h-auto w-full object-cover cursor-pointer rounded"
              src={require("../assets/" + images[1])}
              alt=""
            />
          </div>
          <div className="absolute inset-0 flex cursor-pointer items-center justify-center bg-black bg-opacity-60 rounded" onClick={handleOpenGallery}>
            <span className="text-white font-medium">+ {images.length} más</span>
          </div>
        </div>
      </div>
      <GalleryModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
}
