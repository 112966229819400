import React from "react";
import "../style/about.css";

function About() {
  return (
    <main>
      <div className="container p-4 md:p-20 pt-28 space-y-6">
        <h1 className="text-xl">Sobre nosotros</h1>
        <p className="paragraphs-policy">
          ¡Bienvenido a nuestra página de reservas de citas! Aquí puedes
          programar fácilmente una cita con varios negocios de cuidado personal,
          incluyendo peluquerías, estéticas, y centros de belleza para hombres y
          mujeres.
        </p>
        <p className="paragraphs-policy">
          Nuestro sistema de reservas en línea es fácil de usar y está
          disponible las 24 horas del día, los 7 días de la semana. Simplemente
          selecciona el negocio que deseas visitar, elige el servicio que
          deseas, y luego selecciona el horario y la fecha que mejor se adapte a
          tu agenda. También puedes agregar comentarios especiales para que el
          negocio pueda personalizar tu experiencia según tus necesidades.
        </p>
        <p className="paragraphs-policy">
          En nuestra página de reservas de citas, puedes estar seguro de que tu
          experiencia de cuidado personal será agradable, relajante y
          completamente personalizada según tus necesidades. Nuestros negocios
          asociados cuentan con profesionales altamente capacitados y utilizan
          solo los mejores productos de belleza y cuidado personal para
          garantizar que cada servicio sea de alta calidad.
        </p>
        <p className="paragraphs-policy">
          ¡Reserva tu cita hoy y deja que nuestros negocios asociados cuiden de
          ti y de tus necesidades de cuidado personal!
        </p>
      </div>
    </main>
  );
}

export default About;
