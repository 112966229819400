import React from "react";
import { useBookingContext } from "../context/bookingContext";

const BarberServices = () => {
  const { selectedService, openBusiness, handleSelectedServiceChange } = useBookingContext();
  const radios = openBusiness.services;

  return (
    <div id="reservar" className="pt-8">
      <h2 className="font-bold pb-4">Servicios</h2>
      <div
        className="bg-pink-50 border-t-2 border-pink-500 rounded-lg p-4 mb-4 dark:bg-pink-800/30"
        role="alert"
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <span className="inline-flex justify-center items-center w-8 h-8 rounded-full border-4 border-pink-100 bg-pink-200 text-pink-800 dark:border-pink-900 dark:bg-pink-800 dark:text-pink-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </span>
          </div>
          <div className="ms-3">
            <h3 className="text-gray-800 font-semibold dark:text-white">
              Selecciona el servicio/s que te interesa/n
            </h3>
            <p className="text-sm text-gray-700 dark:text-gray-400">
              Puedes seleccionar hasta un máximo de tres servicios por reserva.
            </p>
          </div>
        </div>
      </div>
      <ul className="space-y-3">
        {radios.map((item, idx) => (
          <li key={idx}>
            <label htmlFor={item.name} className="block relative">
              <input
                id={item.name}
                type="radio"
                checked={selectedService === item.name}
                onChange={() => handleSelectedServiceChange([item.name])}
                name="payment"
                className="sr-only peer"
              />
              <div className="flex items-center justify-between w-full p-5 cursor-pointer rounded-sm border bg-white shadow-sm ring-accent-normal peer-checked:ring-2 duration-200">
                <div className="pl-7">
                  <h3 className="leading-none text-gray-800 font-bold">
                    {item.name}
                  </h3>
                  <p className="mt-1 text-sm text-gray-600 pr-1.5">
                    {item.description}
                  </p>
                </div>
                <div className="text-md text-gray-800 font-bold" style={{ whiteSpace: 'nowrap' }}>
                  {item.price + " €"}
                </div>
              </div>
              <span className="block absolute top-5 left-5 border peer-checked:border-[5px] peer-checked:border-accent-normal w-4 h-4 rounded-full"></span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BarberServices;
