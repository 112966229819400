import React from "react";
import { useBookingContext } from "../context/bookingContext";

export default function ServicesBasketOffCanvas() {
  const { selectedService, setIsBookingProcessOpen } = useBookingContext();

  const handleSelectDateAndTimeClick = () => {
    setIsBookingProcessOpen(true);
  };

  if (selectedService !== "") {
    return (
      <div
        id="drawer-bottom-example"
        className="fixed grid grid-cols-2 gap-4 bottom-0 left-0 right-0 z-40 p-4 transition-transform bg-gray-100 dark:bg-gray-800 shadow-2xl transform-none"
        tabIndex="-1"
        aria-labelledby="drawer-bottom-label"
      >
        <div className="container flex py-2 items-center mx-auto w-full max-w-md">
          <p className="max-w-lg text-sm text-black dark:text-gray-400">
            Selecciona el servicio que te interesa.
          </p>
        </div>
        <div className="container flex py-2 items-center justify-end mx-auto w-full max-w-md">
          <button
            type="button"
            className="hidden sm:inline-flex px-6 py-3.5 text-base font-medium text-white items-center bg-accent-normal hover:bg-accent-hover focus:ring-4 focus:outline-none focus:ring-teal-300 rounded text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-neutral-500"
            onClick={handleSelectDateAndTimeClick}
          >
            Escoge dia y hora
          </button>
          <button
            type="button"
            className="inline-flex sm:hidden px-6 py-3.5 text-base font-medium text-white items-center bg-accent-normal hover:bg-accent-hover focus:ring-4 focus:outline-none focus:ring-teal-300 rounded text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-neutral-500"
            onClick={handleSelectDateAndTimeClick}
          >
            Siguiente
          </button>
        </div>
      </div>
    );
  }
  return null;
}
